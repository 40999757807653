import '../Style.css'
import insta from '../Logo/whiteInstaLogo.png'

function Meny() {
	return (    
		<header className="meny">
			<div className="">
				<div className="t-align-start t-white"><h3 className="menuTitle" >Gaz Pictures</h3></div>
				<div className="padding-right pAbsolute flex">
				</div>
			</div>
		</header>
	);
}

export default Meny;
